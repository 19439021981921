import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { type SubmitHandler, useForm } from 'react-hook-form';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DEV_URL, rget } from "../../utils/requests";
import Div from 'components/Div';
import FormInput from 'components/FormInput';
import LoadingIndicator from 'components/LoadingIndicator';
import type { ICompanyFilters, ICompanyFiltersResponse } from 'features/company/types/company-filters.type';
import ElectricityIcon from 'components/icons/ElectricityIcon';
import GasIcon from 'components/icons/GasIcon';

type CompanyFiltersProps = {
    hide: () => void;
    filters: ICompanyFilters;
    extra: any,
    setFilters: Dispatch<SetStateAction<ICompanyFilters>>;
};
    const emptyFilters: ICompanyFilters = {
        commodity: '0',
        search: '',
        state: '',
        licenseType: ''
    };

const CompanyFilters = ({ hide, filters, setFilters, extra }: CompanyFiltersProps) => {
    //const { data, isLoading, error } = getCompanyFilters();
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm <ICompanyFilters> ({
        defaultValues: { ...filters }
        
    });
    const [licenseTypeOptions, setLicenseTypeOptions] = useState<any[]>([{ value: '', text: 'Both' }, { value: 'ABC', text: 'ABC' }, { value: 'Supplier', text: 'Supplier' }]);
    const [statesList, setStatesList] = useState(extra?.stateList ?? []);
    //const {
    //    data: customers,
    //    isLoading: isLoadingCustomers,
    //    error: errorCustomers
    //} = getCustomersByKey(customersKey);

    const onSubmit: SubmitHandler<ICompanyFilters> = (data) => {
        setFilters(data);
        hide();
        extra?.onApply(data);
    };
    
    //if (isLoading) {
    //    return (
    //        <Div className="text-center">
    //            <LoadingIndicator />
    //        </Div>
    //    );
    //}
  
    return (
        <Div className="p-2">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Label>Commodity</Form.Label>
                <Div className="d-flex justify-content-between mb-3">
                    <FormInput type="radio" name="commodity" register={register} icon={<ElectricityIcon />} value={'1'} label="Electric" />
                    <FormInput type="radio" name="commodity" register={register} icon={<GasIcon />} value={'2'} label="Nat Gas" />
                </Div>
                <Div className="justify-content-center mb-3">
                    <FormInput type="radio" name="commodity" register={register} icon={<>
                        <ElectricityIcon />
                        <GasIcon />
                    </>} value={'0'} label="Both" />
                </Div>
                <FormInput
                    type="input"
                    label="Search"
                    placeholder="Company, Domain, LicenseNo."
                    name="search"
                    register={register}
                    errors={errors}
                    containerClass={'mb-3'}
                />
                <FormInput
                    type="select"
                    label="License Type"
                    name="licenseType"
                    register={register}
                    options={licenseTypeOptions}
                    errors={errors}
                    containerClass={'mb-3'}
                />
                <FormInput
                    type="select"
                    label="State"
                    name="state"
                    register={register}
                    options={statesList.map((s) => {
                        return {value:s.code,text:s.name}
                    })}
                    errors={errors}
                    containerClass={'mb-3'}
                />
                <Div>
                    <Button
                        type="button"
                        variant="primary"
                        onClick={() => {
                            setFilters(emptyFilters);
                            reset(emptyFilters);
                            //hide();
                            extra?.onApply(emptyFilters);
                        }}
                    >
                        Reset
                    </Button>
                    <Button className="float-end" type="submit" variant="primary"> Apply </Button>
                </Div>
            </Form>
        </Div>
    )

};

export { CompanyFilters, emptyFilters };

