import { Link } from 'react-router-dom';
import { Row } from '@tanstack/react-table';

type LinkColumnProps<T> = {
    value: string;
    row?: Row<T>;
    url: string | ((arg: string) => string);
    urlProperty?: string;
    state?: { redirectTo: string };
    newTab?: boolean;
    className?: string;
};

const LinkColumn = <T extends object = Record<string, never>>({
    url,
    urlProperty,
    value,
    row,
    state,
    newTab,
    className
}: LinkColumnProps<T>) => {
    if (!url || (typeof url === 'function' && !row.original[urlProperty])) {
        return <>{value}</>;
    }
    return (
        <Link
            className={className}
            to={typeof url === 'string' ? url : url(row.original[urlProperty])}
            state={state || { redirectTo: window.location.pathname }}
            target={newTab ? '_blank' : '_self'}
        >
            {value}
        </Link>
    );
};

export default LinkColumn;
