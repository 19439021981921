import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Form, Tab, Nav } from 'react-bootstrap';
import { rpost } from '../../utils/requests';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';


const CompaniesDataNew = () => {
    const [companyData, setCompanyData] = useState([]);
    const [companyDataCount, setCompanyDataCount] = useState([]);
    const [companyDataPage, setCompanyDataPage] = useState(1);
    const [companyDataPerPage, setCompanyDataPerPage] = useState(50);
    const [loadingCompanyData, setLoadingCompanyData] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const companyNameColSort = (row1, row2) => {
        return (row1?.name || "").localeCompare(row2?.name || "")
    }
    const columns = [
        {
            name: 'Company Name',
            selector: row => <Link to={{ pathname: `/companies/companiesDetailsDataNew/${row.id}` }}>{row.legalName}</Link>,
            grow: 2.5,
            sortable: true,
            sortFunction: companyNameColSort
        },
        {
            name: 'Number of Contacts',
            selector: row =>row.contactsCount  ?? parseInt(Math.random() * 10),
            grow: 1,
            center: true,
            sortable: true
        },
        {
            name: 'Number of Licenses',
            selector: row =>  row.licenseTypesCount ?? parseInt(Math.random() * 10),
            grow: 1,
            center: true,
            sortable: true
        },
        {
            name: 'License Type',
            selector: row => row.bussinessType ?? "N/A",
            grow: 1,
            center: true,
            sortable: true
        }

    ];

    const handleAliases = aliases => {

        var finalAliases = '';

        aliases.split('|').map(function (str) {

            if (str.trim().toLowerCase() != 'n/a') {
                finalAliases += str + ' - ';
            }
        })

        return finalAliases.slice(0, -3);
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Rows per Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const handleCompanyDataPageChange = companyDataPage => {
        setCompanyDataPage(companyDataPage);
    };

    const handleCompanyDataPerRowsChange = companyDataPerPage => {
        setCompanyDataPerPage(companyDataPerPage);
    };
    useEffect(async () => {
        setLoadingCompanyData(true);
        // const companyDataResponse = await rget(`/api/sqlserver/companyAliases?page=${companyDataPage}&perPage=${companyDataPerPage}&searchText=${searchText}`);
        const companyDataResponse = await rpost(`/api/company/getall`, {"PageNumber":companyDataPage, "PageSize":companyDataPerPage, "SearchTerm": searchText});

        // const companyDataCountResponse = await rget(`/api/sqlserver/companyAliasesCount`);
        setCompanyData(companyDataResponse.data.company);
        setCompanyDataCount(1863);
        setLoadingCompanyData(false);

        return () => {
            setCompanyData();
            setCompanyDataCount();
        };
    }, [companyDataPage, companyDataPerPage, searchText]);

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Body>
                        <h4 className="header-title">
                            {"About companies"}
                        </h4>
                        <p className="text-muted font-14">
                            {"Here is the information directly associated with the companies emphasizing their name, list of aliases, number of contacts and number of licenses. If you want to know more about a particular company you can click on it and navigate to its details."}
                        </p>
                        <hr className="my-2" />

                        <Card>
                            <Card.Body>
                                <Row className='mb-4'>
                                <Col xl={3} className='d-flex justify-content-between'>
                                        <Form.Control
                                            type='text'
                                            onChange={(e) => {setSearchVal(e.target.value)}}
                                            placeholder={"Search..."}
                                            value={searchVal}
                                            onKeyDown={(e) => e.key === "Enter" ? setSearchText(searchVal) : ""}
                                            className='w-80'
                                        />

                                        <Button
                                            disabled={!searchVal}
                                            onClick={() => {
                                                setSearchText(searchVal);
                                            }}
                                            className='ms-1 w-20'
                                        >
                                            <i className='uil-search'></i>
                                        </Button>
                                    </Col>
                                    <Col xl={9}></Col>
                                    
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        {companyData &&
                                            <DataTable
                                                columns={columns}
                                                data={companyData}
                                                progressPending={loadingCompanyData}
                                                progressComponent={<Spinner />}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={companyDataCount}
                                                onChangeRowsPerPage={handleCompanyDataPerRowsChange}
                                                onChangePage={handleCompanyDataPageChange}
                                                paginationComponentOptions={paginationComponentOptions}
                                                paginationPerPage={50}
                                                paginationRowsPerPageOptions={[50, 100, 200, 500]}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
        );
};

export default CompaniesDataNew;