import React, { useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const CustomAuthContext = createContext({ isAuth: false, user: null, logout: null });

function CustomAuthProvider(props) {

    useEffect(() => {
        getAccessToken();
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
    };

    async function getAccessToken() {
        try {
            const token = await props.getToken();
            localStorage.setItem("token", token);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <CustomAuthContext.Provider value={{ logout }}>
            {props.children}
        </CustomAuthContext.Provider>
    );
}

const useAuth = () => useContext(CustomAuthContext);

export { useAuth };

export default CustomAuthProvider;

CustomAuthProvider.propTypes = {
    getToken: PropTypes.func
};