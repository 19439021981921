import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Div from 'components/Div';

type PageTitleProps = {
    title: string;
    backURL?: string;
    componentTitle?: ReactNode;
    componentInfo?: ReactNode;
    icon?: ReactNode;
};

const PageTitle = ({
    title,
    backURL,
    componentTitle,
    componentInfo,
    icon
}: PageTitleProps) => {
    const { state } = useLocation();
    return (
        <Div className="page-title-box d-flex my-3 flex-grow-1">
            <Div className="me-2">{icon}</Div>
            <Div>
                <h4 className=" my-0 py-0">
                    {title} {componentTitle && <>| {componentTitle}</>}
                </h4>
                {componentInfo}
            </Div>
        </Div>
    );
};

export default PageTitle;
