/**
 * A no-op utility function.
 */
export const noop = () => {
    // do nothing :)
};

/**
 * A no-op utility asynchronous function.
 */
// TODO Fix typescript error
// export async function asyncNoop<T = unknown>(): Promise<T> {
//     // do nothing :)
//     return;
// }

/**
 * A utility function that returns always return `true`.
 */
export const justTrue = () => true;

/**
 * A utility function that returns always return `true`.
 */
export const justFalse = () => false;

/**
 * A factory that return a utility function that always returns the propvided value.
 */
export function just<T>(val: T) {
    return () => val;
}

/**
 * Prevents the default behavior of an event.
 *
 * @param event The event
 */
export function preventEventDefault(event: { preventDefault: () => void }) {
    event.preventDefault();
}
