import { Link } from 'react-router-dom';
import { type ReactNode, useRef, useEffect } from 'react';
import classNames from 'classnames';

import Div from 'components/Div';
import { manageBodyClass } from 'utils/manage-body-class';

type DrawerProps = {
    title?: string;
    isOpen: boolean;
    hide: () => void;
    content: ReactNode;
};

export type DrawerContentProps = {
    hide: () => void;
};

const Drawer = ({ isOpen, hide, title, content }: DrawerProps) => {
    const rightBarNodeRef = useRef<HTMLDivElement>(null);

    const handleClose = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        event.preventDefault();
        hide();
    };

    useEffect(() => {
        if (isOpen) {
            manageBodyClass('end-bar-enabled', 'add');
        } else {
            manageBodyClass('end-bar-enabled', 'remove');
        }
    }, [isOpen]);

    return (
        <>
            <div className="end-bar" ref={rightBarNodeRef}>
                <Div className="rightbar-title">
                    <Link
                        to="#"
                        className="end-bar-toggle float-end"
                        onClick={handleClose}
                    >
                        <i className="dripicons-cross noti-icon"></i>
                    </Link>
                    <h5 className="m-0">{title || 'Filters'}</h5>
                </Div>

                <Div className="rightbar-content h-100 overflow-auto">
                    {content}
                </Div>
            </div>

            <Div
                className={classNames('fade modal-backdrop', {
                    show: isOpen,
                    'd-none': !isOpen
                })}
                onClick={hide}
            ></Div>
        </>
    );
};

export default Drawer;
