import { ReactNode } from 'react';

type TextProps = {
    children?: ReactNode;
    variant?:
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'paragraph'
        | 'subtitle'
        | 'bold'
        | 'plain'
        | 'link';
    style?: React.CSSProperties;
    url?: string;
    className?: string;
    target?: string;
};

const Text = ({ variant, children, style, className,url,target }: TextProps) => {
    switch (variant) {
        case 'h1':
            return (
                <h1 className={className} style={style}>
                    {children}
                </h1>
            );
        case 'h2':
            return (
                <h2 className={className} style={style}>
                    {children}
                </h2>
            );
        case 'h3':
            return (
                <h3 className={className} style={style}>
                    {children}
                </h3>
            );
        case 'h4':
            return (
                <h4 className={className} style={style}>
                    {children}
                </h4>
            );
        case 'h5':
            return (
                <h5 className={className} style={style}>
                    {children}
                </h5>
            );
        case 'h6':
            return (
                <h6 className={className} style={style}>
                    {children}
                </h6>
            );
        case 'paragraph':
            return (
                <p className={className} style={style}>
                    {children}
                </p>
            );
        case 'subtitle':
            return (
                <h6
                    className={`text-muted ${className}`}
                    style={{ fontWeight: 500, ...style }}
                >
                    {children}
                </h6>
            );
        case 'bold':
            return (
                <b className={className} style={style}>
                    {children}
                </b>
            );
        case 'plain':
            return (
                <span className={className} style={style}>
                    {children}
                </span>
            );
        case 'link':
            return (
                <a className={className} style={style} href={url} target={target ?? "_blank"}>
                    {children}
                </a>
            );
        default:
            return (
                <p className={className} style={style}>
                    {children}
                </p>
            );
    }
};

export default Text;
