import { HTMLProps, useEffect, useRef } from 'react';

type IndeterminateCheckboxProps = {
    indeterminate?: boolean;
} & HTMLProps<HTMLInputElement>;

// TODO change for bootstrap checkbox
const IndeterminateCheckbox = ({
    indeterminate,
    className = '',
    ...rest
}: IndeterminateCheckboxProps) => {
    const ref = useRef<HTMLInputElement>(null!);

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate]);

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    );
};

export default IndeterminateCheckbox;
