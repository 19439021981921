import { useCallback, useState } from 'react';

import { useFilters } from './useFilters';
import { useOrderBy } from './useOrderBy';
import { usePagination } from './usePagination';
import { useSearch } from './useSearch';
import { useSearchParams } from 'react-router-dom';
import { usePaginationQueryParams } from './use-pagination-query-params.hook';

type UseApiFiltersValues<OrderByType, FiltersType> = {
    search?: string;
    orderBy?: keyof OrderByType;
    ascendingOrder?: boolean;
    limit?: number;
    page?: number;
    filters?: FiltersType;
};

type UseApiFiltersOptions = {
    writeToUrl?: boolean;
};

export function useApiFilters<OrderByType, FiltersType = any>(
    defaultData: UseApiFiltersValues<OrderByType, FiltersType> = {},
    options: UseApiFiltersOptions = {
        writeToUrl: true
    }
) {
    const { searchParams, setSearchParams, pageParam, limitParam } =
        usePaginationQueryParams();

    const { search, setSearch } = useSearch(defaultData.search);
    const { filters, setFilters } = useFilters<FiltersType>(
        defaultData.filters
    );
    const { orderBy, setOrderBy, ascendingOrder, setAscendingOrder } =
        useOrderBy<OrderByType>({
            orderBy: defaultData.orderBy,
            ascendingOrder: defaultData.ascendingOrder
        });
    const {
        limit,
        setLimit: _setLimit,
        page,
        setPage: _setPage
    } = usePagination({
        limit: limitParam || defaultData.limit,
        page: pageParam || defaultData.page
    });

    const setPage = useCallback(
        (page: number) => {
            if (options.writeToUrl) {
                searchParams.set('page', `${page + 1}`);
            }
            // setSearchParams('page', page + 1);
            _setPage(page);
        },
        [searchParams, setSearchParams, _setPage]
    );

    const setLimit = useCallback(
        (limit: number) => {
            if (options.writeToUrl) {
                searchParams.set('limit', `${limit}`);
            }
            // setSearchParams('limit', `${limit}`);
            _setLimit(limit);
        },
        [searchParams, setSearchParams, _setLimit]
    );

    const hook = useCallback(() => {
        return {
            search,
            setSearch,
            orderBy,
            setOrderBy,
            ascendingOrder,
            setAscendingOrder,
            limit,
            setLimit,
            page,
            setPage,
            filters,
            setFilters
        };
    }, [
        search,
        setSearch,
        orderBy,
        setOrderBy,
        ascendingOrder,
        setAscendingOrder,
        limit,
        setLimit,
        page,
        setPage,
        filters,
        setFilters
    ]);

    return {
        filters,
        setFilters,
        orderBy,
        setOrderBy,
        ascendingOrder,
        setAscendingOrder,
        limit,
        setLimit,
        page,
        setPage,
        search,
        setSearch,
        hook
    };
}
