import { useState } from 'react';

type UsePaginationValues = {
    limit?: number;
    page?: number;
};

export const DEFAULT_PAGINATION_LIMIT = 50;

export const usePagination = (defaultData: UsePaginationValues = {}) => {
    const [limit, setLimit] = useState<number>(
        defaultData.limit || DEFAULT_PAGINATION_LIMIT
    );
    const [page, setPage] = useState<number>(defaultData.page || 0);

    return { limit, page, setLimit, setPage };
};
