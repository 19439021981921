import { IContact, IAddress } from "pages/companies/types";
import Table from "components/Table/index";
import { titleConvert, OnlyAddress } from './../../../../helpers/api/utils';
import { ColumnDef } from "@tanstack/table-core";

type ContactsDataProps = {
    contacts?: IContact[];
    hiddenColumns?: string[];
    enableActions?: boolean;
    loading?: boolean;
    total?: number;
    hook?: any
};

type AddressDataProps = {
    addresses?: IContact[];
    hiddenColumns?: string[];
    enableActions?: boolean;
    loading?: boolean;
    total?: number;
    hook?: any
};

const contactColumns: ColumnDef<IContact>[] = [
    {
        header: "Full Name",
        accessorKey: "contactName",
        enableSorting: false,
        cell: (props) => <span>{`${titleConvert(props.row.original.contactName ?? "")}`}</span>
        ,
    },
    {
        header: "Email",
        accessorKey: "contactEmail",
        enableSorting: false,
        cell: (props) => <a className="link-color" href={`mailto:${props.row.original.contactEmail}`}>{props.row.original.contactEmail ?? ""}</a>
    },
    {
        header: "Phone",
        accessorKey: "contactPhone",
        enableSorting: false,
        cell: (props) => <a className="link-color" href={`tel:${props.row.original.contactPhone}`}>{`${props.row.original.contactPhone ?? ""}`}</a>
    },
    {
        header: "Role",
        accessorKey: "contactRole",
        enableSorting: false,
        cell: (props) => <span>{`${titleConvert(props.row.original.contactRole ?? "")}`}</span>
    }
];

const addressColumns: ColumnDef<IAddress>[] = [
    {
        header: "Address",
        accessorKey: "contactAddress",
        enableSorting: false,
        cell: (props) => <a className="link-color" rel="noreferrer" target="_blank" href={`https://maps.google.com/?q=${props.row.original.contactAddress}`}>{`${OnlyAddress(props.row.original.contactAddress ?? "", props.row.original.contactCity ?? "", props.row.original.stateCode ?? "", props.row.original.contactZip ?? "")}`}</a>
    },
    {
        header: "City",
        accessorKey: "contactCity",
        enableSorting: false,
        cell: (props) => <span>{`${titleConvert(props.row.original.contactCity ?? "")}`}</span>
    },
    {
        header: "State",
        accessorKey: "stateCode",
        enableSorting: false,
        cell: (props) => <span style={{textTransform: 'uppercase'}}>{`${titleConvert(props.row.original.stateCode ?? "")}`}</span>
    },
    {
        header: "Zipcode",
        accessorKey: "contactZip",
        enableSorting: false,
        cell: (props) => <span>{props.row.original.contactZip ?? ""}</span>
    }
];

export const ContactsDataTable = ({
    contacts,
    loading,
}: ContactsDataProps) => {
    return (
        <>
            <Table<IContact> columns={contactColumns} data={contacts} loading={loading} enableSearch={false} enablePagination={false} enableHideColumns={false} />
        </>
    );
};

export const AddressDataTable = ({
    addresses,
    loading,
}: AddressDataProps) => {
    return (
        <>
            <Table<IAddress> columns={addressColumns} data={addresses} loading={loading} enableSearch={false} enablePagination={false} enableHideColumns={false} />
        </>
    );
};

