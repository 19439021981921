import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export type PageSize = {
    text: string;
    value: number;
};

type PaginationProps = {
    limit: number;
    setLimit: Dispatch<SetStateAction<number>>;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    total: number;
    sizePerPageList: PageSize[];
    disabled?: boolean;
};

const Pagination = ({
    limit,
    setLimit,
    page,
    setPage,
    total,
    sizePerPageList,
    disabled
}: PaginationProps) => {
    const totalPages = useMemo(
        () => (total ? Math.ceil(total / limit) : 1),
        [total, limit]
    );

    useEffect(() => {
        if (limit === 0) {
            setLimit(sizePerPageList[0].value);
        }
    }, []);

    /**
     * get filter pages
     */
    const filterPages = useCallback(
        (visiblePages: number[]) => {
            return visiblePages.filter((page: number) => page <= totalPages);
        },
        [totalPages]
    );

    /**
     * handle visible pages
     */
    const getVisiblePages = useCallback(
        (page: number, total: number) => {
            if (total < 7) {
                return filterPages([1, 2, 3, 4, 5, 6]);
            } else {
                if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                    return [1, page - 1, page, page + 1, total];
                } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                    return [1, total - 3, total - 2, total - 1, total];
                } else {
                    return [1, 2, 3, 4, 5, total];
                }
            }
        },
        [filterPages]
    );

    /**
     * handle page change
     * @param page - current page
     * @returns
     */
    const changePage = (newPage: number) => {
        const activePage = page + 1;

        if (newPage === activePage) {
            return;
        }

        const visiblePages = getVisiblePages(newPage, totalPages);
        setVisiblePages(filterPages(visiblePages));

        setPage(newPage - 1);
    };

    const [visiblePages, setVisiblePages] = useState<number[]>(
        getVisiblePages(0, totalPages)
    );
    const activePage: number = page + 1;

    useEffect(() => {
        const visiblePages = getVisiblePages(page + 1, totalPages);
        setVisiblePages(visiblePages);
    }, [totalPages, getVisiblePages, page]);

    return (
        <div className="d-lg-flex align-items-center text-center pb-1">
            {sizePerPageList.length > 0 && (
                <div className="d-inline-block me-3">
                    <label className="me-1">Display :</label>
                    <select
                        value={limit}
                        onChange={(e) => {
                            setLimit(Number(e.target.value));
                        }}
                        className="form-select d-inline-block w-auto"
                        disabled={disabled}
                    >
                        {(sizePerPageList || []).map((pageSize, index) => {
                            return (
                                <option
                                    key={index.toString()}
                                    value={pageSize.value}
                                >
                                    {pageSize.text}
                                </option>
                            );
                        })}
                    </select>
                </div>
            )}

            <span className="me-3">
                Page{' '}
                <strong>
                    {page + 1} of {total ? Math.ceil(total / limit) : 1}
                </strong>{' '}
            </span>

            <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2">
                <label>Go to page : </label>
                <input
                    type="number"
                    value={page + 1}
                    min="1"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const page = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                        setPage(page);
                    }}
                    className="form-control w-25 ms-1 d-inline-block"
                    disabled={disabled}
                />
            </span>

            <ul className="pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0">
                <li
                    key="prevpage"
                    className={classNames(
                        'page-item',
                        'paginate_button',
                        'previous',
                        {
                            disabled: activePage === 1
                        }
                    )}
                    onClick={() => {
                        if (activePage === 1) {
                            return;
                        }
                        changePage(activePage - 1);
                    }}
                >
                    <Link to="#" className="page-link">
                        <i className="mdi mdi-chevron-left"></i>
                    </Link>
                </li>
                {(visiblePages || []).map((page, index, array) => {
                    return array[index - 1] + 1 < page ? (
                        <React.Fragment key={page.toString()}>
                            <li className="page-item disabled d-none d-xl-inline-block">
                                <Link to="#" className="page-link">
                                    ...
                                </Link>
                            </li>
                            <li
                                className={classNames(
                                    'page-item',
                                    'd-none',
                                    'd-xl-inline-block',
                                    {
                                        active: activePage === page,
                                        disabled
                                    }
                                )}
                                onClick={() => changePage(page)}
                            >
                                <Link to="#" className="page-link">
                                    {page}
                                </Link>
                            </li>
                        </React.Fragment>
                    ) : (
                        <li
                            key={page.toString()}
                            className={classNames(
                                'page-item',
                                'd-none',
                                'd-xl-inline-block',
                                {
                                    active: activePage === page,
                                    disabled
                                }
                            )}
                            onClick={() => !disabled && changePage(page)}
                        >
                            <Link to="#" className="page-link">
                                {page}
                            </Link>
                        </li>
                    );
                })}
                <li
                    key="nextpage"
                    className={classNames(
                        'page-item',
                        'paginate_button',
                        'next',
                        {
                            disabled: activePage === totalPages
                        }
                    )}
                    onClick={() => {
                        if (activePage === totalPages) {
                            return;
                        }
                        changePage(activePage + 1);
                    }}
                >
                    <Link to="#" className="page-link">
                        <i className="mdi mdi-chevron-right"></i>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
