import React from 'react';
import Spinner from './Spinner';

type ButtonSpinnerProps = {
};

const ButtonSpinner = (props: ButtonSpinnerProps ): React$Element<any> => {
    return (
        <Spinner className="spinner-border-sm" tag="span" color="white" />
    );
};

export default ButtonSpinner;
