import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { StateSummaryDataTable } from '../dashboard/StateSummary/index';
import { sortByProperty, filterRecords } from '../../helpers/api/utils';
import { DEV_URL,rget } from '../../utils/requests';

const DashboardPage = () => {
    const [stateSummaryData, setStateSummaryData] = useState();
    const [loadingStateSummaryData, setLoadingStateSummaryData] = useState();
    useEffect(async () => {
        const apiCallResponse = await rget(
            `${DEV_URL}/api/License/GetStateWiseLicenseCount`
        );
        setStateSummaryData(apiCallResponse.data);
        //console.log(sortByProperty(filterRecords(apiCallResponse.data.licenseDetails, ['state', 'gas', 'electric', 'totalLicenses']), ['state']));
        setLoadingStateSummaryData(false);
        return () => {
            setStateSummaryData();
        };
    },[]);

    return (
        <>
        <Card>
            <span className="px-3 pt-3">
                <h4 className="header-title">
                    {"Welcome to Insights!"}
                </h4>
                <p className="text-muted font-14 mb-4">
                    {"Licensing data, aggregated and anonymized transaction data, publicly available pricing data, consumption data, demand data - all come together to tell a story unlike any that can be told by a single company."}
                </p>
            </span>
            </Card>
            <Card>
                <div className="p-3">
                    {stateSummaryData?.licenseDetails && (
                        <StateSummaryDataTable statesummary={(sortByProperty(filterRecords(stateSummaryData?.licenseDetails, ['state', 'gas', 'electric', 'totalLicenses']), ['state']))}
                            loading={loadingStateSummaryData}></StateSummaryDataTable>
                    )}
                </div>
            </Card>
        </>);
};

export default DashboardPage;
