import Badge from 'react-bootstrap/Badge';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { format } from 'date-fns';
import { emptyFilters } from './../../pages/companies/CompanyFilter';
import Div from 'components/Div';
import { ApiFilters } from 'types/api-inputs.type';

type AppliedFiltersProps<TableValues> = {
    hook?: () => ApiFilters<TableValues>;
    filterClose?: any;
};

const AppliedFilters = <TableValues extends object = Record<string, never>>({
    hook,
    filterClose
}: AppliedFiltersProps<TableValues>) => {
    const { filters, setFilters } = hook();
    return (
        <Div className="d-flex mb-2">
            {filters &&
                Object.keys(filters)?.map((key) => (
                    <FilterBadge
                        filters={filters}
                        key={key}
                        keyString={key}
                        setFilter={setFilters}
                        onCancel={filterClose}
                    />
                ))}
        </Div>
    );
};

export default AppliedFilters;

type FilterBadgeProps = {
    filters: any;
    keyString: string;
    setFilter: Dispatch<SetStateAction<any>>;
    onCancel?: any;
};
const FilterBadge = ({
    filters,
    keyString: key,
    setFilter,
    onCancel
}: FilterBadgeProps) => {
    const dateFormat  = "MM/dd/yy";
    let Filter = <></>;
    let filterName = '';

    if (filters[key] === null || filters[key] === undefined) {
        return <></>;
    }

    filterName = key;

    if (key === 'commodity') {
        switch (filters[key]) {
            case '1':
                Filter = <> Electric</>;
                break;
            case '2':
                Filter = <> Nat Gas</>;
                break;
            default:
                return <></>;
        }
    } else {
        if (Array.isArray(filters[key])) {
            if (filters[key].length > 0) {
                Filter = filters[key]?.map((item) => (
                    <Fragment key={item.id}> {item.name}</Fragment>
                ));
            } else {
                return <></>;
            }
        } else if (typeof filters[key] === 'string') {
            if (filters[key] === '') {
                return <></>;
            }
            Filter = <> {filters[key]}</>;
        } else if (typeof filters[key] === 'boolean') {
            if (filters[key] === false) {
                return <></>;
            }
            Filter = <></>;
            filterName = booleanFiltersName(key);
        } else if ('name' in filters[key]) {
            Filter = <> {filters[key].name}</>;
        } else if ('toDate' in filters[key]) {
            Filter = <> {format(filters[key].toDate(), dateFormat)}</>;
        }
    }

    const closeFilter = () => {
        debugger
        if (Array.isArray(filters[key])) {
                setFilter((prev) => {
                    return { ...prev, [key]: [] };
                });
        } else {
            if (key === 'commodity') {
                setFilter((prev) => {
                    return { ...prev, [key]: '0' };
                });
            }
            else {
                setFilter((prev) => {
                    return { ...prev, [key]: null };
                });
            }
        }
        if (onCancel != null && onCancel !== undefined) {
            let data = filters;
            data[key] = emptyFilters[key];
            onCancel(data);
        }
    };

    return (
        <Badge className="mx-1" bg="secondary">
            {filterName.toUpperCase()}:{Filter}{' '}
            <i
                className="mdi mdi-close"
                onClick={closeFilter}
                role="button"
            ></i>
        </Badge>
    );
};

function booleanFiltersName(key: string) {
    switch (key) {
        case 'isNatGas':
            return 'Natural Gas';
        case 'isElectric':
            return 'Electric';
        case 'isDirect':
            return 'Direct';
        case 'isIndirect':
            return 'Indirect';
        default:
            return key;
    }
}
