import { useMemo } from 'react';
import { useSearchParams } from './useSearchParams';


export function usePaginationQueryParams() {

    const searchParams = useSearchParams();

    const setSearchParams = (params: any) =>  searchParams.set(params);

    const pageParam = useMemo(
        () =>
            searchParams.get('page') && parseInt(searchParams.get('page')) > 0
                ? parseInt(searchParams.get('page')) - 1
                : undefined,
        [searchParams]
    );

    const limitParam = useMemo(
        () =>
            searchParams.get('limit') && parseInt(searchParams.get('limit')) > 0
                ? parseInt(searchParams.get('limit'))
                : undefined,
        [searchParams]
    );



    return {
        searchParams,
        setSearchParams,
        pageParam,
        limitParam
    };
}
