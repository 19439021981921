import { Fragment, ReactNode } from 'react';
import { format } from 'date-fns';

import Div from 'components/Div';
import TextLine from 'components/TextLine';
import Text from 'components/Text';

export type DetailsHeaderFieldsData = {
    field: string;
    value: string | Date | number;
    url?: string;
};

type DetailsHeaderFieldsProps = {
    args: (DetailsHeaderFieldsData | ReactNode)[];
};

const DetailsHeaderFields = ({ args }: DetailsHeaderFieldsProps) => {
    const dateFormat = "MM/dd/yy"; 

    return (
        <Div className="d-flex flex-wrap">
            {args.map((arg, index) => {
                if (
                    !!arg['field'] &&
                    (arg['value'] === null ||
                        arg['value'] === undefined ||
                        arg['value'] === '')
                ) {
                    return null;
                }
                return (
                    <Fragment key={index}>
                        <Div>
                            {!!arg['field'] ? (
                                <TextLine
                                    primary={`${arg['field']}:`}
                                    secondary={
                                        arg['value'] instanceof Date
                                            ? format(arg['value'], dateFormat)
                                            : arg['value']
                                    }
                                    url={arg['url']}
                                />
                            ) : (
                                (arg as ReactNode)
                            )}
                        </Div>
                    </Fragment>
                );
            })}
        </Div>
    );
};

export default DetailsHeaderFields;
