import React, { useState, useEffect } from 'react';
import { Row, Col, Card, OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import { rget } from '../../utils/requests';
import DataTable from 'react-data-table-component';
import { useParams } from "react-router-dom";
import Spinner from '../../components/Spinner';
import { CompanyDetailsV2 } from './companyDetailsV2';

const CompaniesDetailsData = () => {

    const [companyLicenseData, setCompanyLicenseData] = useState([]);
    const [selectedLicenseUrlButton, setSelectedLicenseUrlButton] = useState('');
    const [companyContactData, setCompanyContactData] = useState([]);
    const [companyAddressData, setCompanyAddressData] = useState([]);
    const [selectedAddressButton, setSelectedAddressButton] = useState('');
    const [loadingCompanyDetailsData, setLoadingCompanyDetailsData] = useState(false);

    let { companyId } = useParams();
    let { companyName } = useParams();

    const licenseColumns = [
        {
            name: 'License Date',
            selector: row =>  new Date(row.date).toLocaleDateString() !== "1/1/1" ? new Date(row.date).toLocaleDateString() : 'N/A',
            grow: 1
        },
        {
            name: 'License Number',
            selector: row => row.number,
            grow: 1
        },
        {
            name: 'License Status',
            selector: row => row.status,
            grow: 1
        },
        {
            name : "State",
            selector: row => row.state,
            grow : 1
        },
        {
            name : "Legal Entity",
            selector : row => row.legalEntity,
            grow : 1
        },
        {
            name: '',
            selector: row =>
                <OverlayTrigger placement="top" overlay={<Tooltip>View Official Website
                </Tooltip>}>
                    <Button variant="primary" disabled={selectedLicenseUrlButton === row.licenseUrl ? true : false}>
                        <i className="dripicons-preview cursor-pointer" onClick={() => handleViewLicenseUrl(row.licenseUrl)}></i>
                    </Button>
                </OverlayTrigger>,
            grow: 1
        }
    ];

    const handleViewLicenseUrl = (urlLicense) => {
        setSelectedLicenseUrlButton(urlLicense);
        window.open(urlLicense, '_blank');
        setSelectedLicenseUrlButton('');
    };

    const contactColumns = [
        {
            name: 'Fullname',
            selector: row => handleFullname(row.firstName, row.lastName),
            grow: 1
        },
        {
            name: 'Primary Phone',
            selector: row => row.primaryPhone,
            grow: 1
        },
        {
            name: 'Secondary Phone',
            selector: row => row.primaryPhone,
            grow: 1
        },
        {
            name: 'Role',
            selector: row => row.role,
            grow: 1
        },
        {
            name: 'Email',
            selector: row => row.email,
            grow: 1
        },
        {
            name: 'Fax',
            selector: row => row.fax,
            grow: 1
        }
    ];

    const handleFullname = (firstName, lastName) => {

        var fullname = '';

        if (firstName != "N/A" && lastName != "N/A") {
            fullname = firstName + ' ' + lastName;
        }
        else if (firstName != "N/A" && lastName == "N/A") {
            fullname = firstName;
        }
        else if (firstName == "N/A" && lastName != "N/A") {
            fullname = lastName;
        }
        else {
            fullname = "Unknown";
        }

        return fullname;
    };

    const addressColumns = [
        {
            name: 'Address',
            selector: row => row.address,
            grow: 1
        },
        {
            name: 'City',
            selector: row => row.city,
            grow: 1
        },
        {
            name: 'State',
            selector: row => row.state,
            grow: 1
        },
        {
            name: 'Zip',
            selector: row => row.zip,
            grow: 1
        },
        {
            name: '',
            selector: row =>
                <OverlayTrigger placement="top" overlay={<Tooltip>View Location Details on Google Maps
                </Tooltip>}>
                    <Button variant="primary" disabled={selectedAddressButton === row.address ? true : false}>
                        <i className="dripicons-location cursor-pointer" onClick={() => handleVisitAddressInformation(row.address, row.city, row.state)}></i>
                    </Button>
                </OverlayTrigger>,
            grow: 1
        }
    ];

    const handleVisitAddressInformation = (address, city, state) => {
        setSelectedAddressButton(address);
        var googleMapUrl = 'https://www.google.com/maps/place/' + address + '+' + city + '+' + state;
        window.open(googleMapUrl, '_blank');
        setSelectedAddressButton('');
    };
    
    useEffect(async () => {
        setLoadingCompanyDetailsData(true);
        // const companyLicensesDataResponse = await rget(`/api/sqlserver/licensesByCompanyId?companyId=${companyId}`);
        // const companyContactsDataResponse = await rget(`/api/sqlserver/contactsByCompanyId?companyId=${companyId}`);
        // const companyAddressesDataResponse = await rget(`/api/sqlserver/addressesByCompanyId?companyId=${companyId}`);
        setCompanyLicenseData([]);
        setCompanyContactData([
            {
                "id": 1,
                "firstName": "N/A",
                "lastName": "N/A",
                "primaryPhone": "443-415-8754",
                "secondaryPhone": "N/A",
                "role": "N/A",
                "email": "N/A",
                "fax": "N/A"
            },
            {
                "id": 2,
                "firstName": "N/A",
                "lastName": "N/A",
                "primaryPhone": "855-756-7283",
                "secondaryPhone": "N/A",
                "role": "N/A",
                "email": "N/A",
                "fax": "N/A"
            },
            {
                "id": 3,
                "firstName": "N/A",
                "lastName": "N/A",
                "primaryPhone": "443-415-8754",
                "secondaryPhone": "N/A",
                "role": "PRESIDENT OF SALES",
                "email": "N/A",
                "fax": "N/A"
            },
            {
                "id": 4,
                "firstName": "N/A",
                "lastName": "N/A",
                "primaryPhone": "855-756-7283",
                "secondaryPhone": "N/A",
                "role": "MANAGING MEMBER",
                "email": "N/A",
                "fax": "N/A"
            }
        ]);
        setCompanyAddressData([
            {
                "address": "117 EDEN HOLLOW LN",
                "city": "WEDDINGTON",
                "state": "Texas",
                "zip": "28104",
                "website": "N/A"
            }
        ]);
        setLoadingCompanyDetailsData(false);

        return () => {
            setCompanyLicenseData();
            setCompanyContactData();
            setCompanyAddressData();
        };
    }, []);

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Body>
                        <h4 className="header-title">
                            {"Company Details"}
                        </h4>
                        <p className="text-muted font-14">
                            Here is the complementary information associated to the company: <b>{ companyName }</b>
                        </p>
                        <hr className="my-2" />
                        <h4 className="header-title mt-3">
                            {"Licenses Information"}
                        </h4>
                        <Row>
                            <Col sm="12">
                                {companyLicenseData &&
                                    <DataTable
                                        columns={licenseColumns}
                                        data={companyLicenseData}
                                        progressPending={loadingCompanyDetailsData}
                                        progressComponent={<Spinner />}
                                    />
                                }
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <h4 className="header-title mt-3">
                            {"Contacts Information"}
                        </h4>
                        <Row>
                            <Col sm="12">
                                {companyContactData &&
                                    <DataTable
                                        columns={contactColumns}
                                        data={companyContactData}
                                        progressPending={loadingCompanyDetailsData}
                                    progressComponent={<Spinner />}
                                    />
                                }
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <h4 className="header-title mt-3">
                            {"Addresses Information"}
                        </h4>
                        <Row>
                            <Col sm="12">
                                {companyAddressData &&
                                    <DataTable
                                        columns={addressColumns}
                                        data={companyAddressData}
                                        progressPending={loadingCompanyDetailsData}
                                        progressComponent={<Spinner />}
                                    />
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        );
};

export default CompaniesDetailsData;