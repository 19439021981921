import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

// const loader_variants = ['primary', 'warning', 'success', 'danger'];
const loader_variants = ['dark', 'dark', 'dark', 'dark', 'dark', ];


const LoadingIndicator = () => {
    const [variantPosition, setVariantPosition] = useState<number>(1);
    useEffect(() => {
        const variantInterval = setInterval(
            () =>
                variantPosition >= loader_variants.length - 1
                    ? setVariantPosition(0)
                    : setVariantPosition((pos) => pos + 1),
            750
        );

        return () => clearInterval(variantInterval);
    }, [variantPosition]);

    return (
        <Spinner
            style={{ width: '2rem', height: '2rem' }}
            animation="border"
            variant={loader_variants[variantPosition]}
        />
    );
};

export default LoadingIndicator;
