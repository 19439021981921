import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const PrivateRoute = ({ layout: Layout, component: Component, ...rest }) => {
    
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    return (
        <Layout>
            <Route
            {...rest}
            render={(props) => {
                if (!isAuthenticated) {
                    return loginWithRedirect({});
                }
                return <Component {...props} />;
            }}
        />
        </Layout>
    );
};

export default PrivateRoute;
