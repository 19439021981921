// @flow
import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
//import './assets/scss/Saas.scss';
import './assets/scss/Creative.scss';
import PrivateRoute from './routes/PrivateRoute';
import Profile from './components/Profile';
import Layout from './layouts/Vertical';
import LogoutPage from './pages/account/Logout';
import licensesRawData from './pages/license/licensesRawData';
import offersRawData from './pages/offers/offersRawData';
import DashboardPage from './pages/dashboard/dashboard';
import Loader from './components/Loader';
import LicensedCompanies from './pages/license/licenseFlow';
import CompaniesData from './pages/companies/companiesData';
import CompaniesDetailsData from './pages/companies/companiesDetailsData';
import CustomAuthProvider from './providers/CustomAuthProvider';
import CompaniesDetailsDataNew from './pages/companies/companiesDetailsDataNew';
import CompaniesDataNew from './pages/companies/companiesDataNew';

const App = () => {

    const { isLoading, error, getAccessTokenSilently } = useAuth0();

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <CustomAuthProvider getToken={getAccessTokenSilently}>
            <Router history={history}>
                <Switch>
                    <PrivateRoute layout={Layout} path="/" exact component={DashboardPage} />
                    <PrivateRoute layout={Layout} path="/licenses/browse" exact component={LicensedCompanies} />
                    <PrivateRoute layout={Layout} path="/licenses/rawData" exact component={licensesRawData} />
                    <PrivateRoute layout={Layout} path="/offers/rawData" exact component={offersRawData} />
                    <PrivateRoute layout={Layout} path="/companies" exact component={CompaniesData} />
                    <PrivateRoute layout={Layout} path="/companies/companiesDataNew" exact component={CompaniesDataNew} />
                    <PrivateRoute layout={Layout} path="/companies/companiesDetailsData/:companyId/:companyName" exact component={CompaniesDetailsData} />
                    <PrivateRoute layout={Layout} path="/companies/:companyId" exact component={CompaniesDetailsDataNew} />
                    <Route path="/account/logout" component={LogoutPage} />
                </Switch>
            </Router>
        </CustomAuthProvider>
    );
};

export default App;
