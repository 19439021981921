import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, forwardRef } from 'react';
import Portal from 'react-overlays/Portal';
import Dropdown from 'react-bootstrap/Dropdown';

const ThreeDotToggle = forwardRef<HTMLDivElement, any>(({ onClick }, ref) => (
    <div
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="d-flex justify-content-center"
        style={{ width: 14 }}
        role="button"
    >
        <FontAwesomeIcon icon={faEllipsisV} />
    </div>
));

ThreeDotToggle.displayName = 'ThreeDotToggle';

type ThreeDotMenuProps = PropsWithChildren;

const ThreeDotMenu = ({ children }: ThreeDotMenuProps) => {
    return (
        <Dropdown>
            <Dropdown.Toggle as={ThreeDotToggle} />
            <Portal container={document.body}>
                <Dropdown.Menu style={{ zIndex: 1100 }}>
                    {children}
                </Dropdown.Menu>
            </Portal>
        </Dropdown>
    );
};

export default ThreeDotMenu;
