import { PageSize } from 'components/Table/Pagination';

export function getTablePaginationSizes(
    lenght: number,
    disableAll = false,
    ...sizes: number[]
) {
    const defaultSizes: PageSize[] = [
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '200', value: 200 }
    ];

    const sizePerPageList: PageSize[] =
        sizes.length > 0
            ? sizes.map((size) => {
                  return { text: `${size}`, value: size };
              })
            : defaultSizes;

    if (disableAll) {
        sizePerPageList.push({ text: 'All', value: lenght });
    }

    return sizePerPageList;
}
