import { faBolt } from '@fortawesome/free-solid-svg-icons';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const ElectricityIcon = ({
    className,
    ...props
}: Omit<FontAwesomeIconProps, 'icon'>) => {
    return (
        <FontAwesomeIcon
            icon={faBolt}
            className={classNames('text-warning', className)}
            {...props}
        />
    );
};

export default ElectricityIcon;
