import React, { useState, useEffect } from 'react';
import { Row, Col, Tab, Nav, Card, Collapse, OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import { rget } from '../../utils/requests';
import DataTable from 'react-data-table-component';
import classnames from 'classnames';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Spinner from '../../components/Spinner';

const LicensedCompaniesDataTable = () => {
 
    const [mostRecentData, setMostRecentData] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [loadingMostRecentData, setLoadingMostRecentData] = useState(false);
    const [loadingHistoricalData, setLoadingHistoricalData] = useState(false);
    const [mostRecentPage, setMostRecentPage] = useState(1);
    const [historicalPage, setHistoricalPage] = useState(1);
    const [mostRecentDataCount, setMostRecentDataCount] = useState();
    const [historicalDataCount, setHistoricalDataCount] = useState();
    const [selectedLicenseUrlButton, setSelectedLicenseUrlButton] = useState('');
    const [mostRecentPerPage, setMostRecentPerPage] = useState(50);
    const [historicalPerPage, setHistoricalPerPage] = useState(50);
    const [licensedCompanyNames, setLicensedCompanyNames] = useState();
    const [licensedCompanyStates, setLicensedCompanyStates] = useState();
    const [licensedCompanyCommodities, setLicensedCompanyCommodities] = useState();
    const [licensedCompanyLicenseTypes, setLicensedCompanyLicenseTypes] = useState();
    const [activeTab, setActiveTab] = useState("");

    const [licensedCompanyFilter, setLicensedCompanyFilter] = useState({
        company: {
            name: '',
            type: '',
            id: '',
            value: ''
        },
        state: {
            id: '',
            name: '',
            value: ''
        },
        commodity: {
            id: '',
            name: '',
            value: ''
        },
        companyLicenseType: ''
    });

    const handleFilteredLicensedCompany = async (isTriggeredByFilter) => {

        switch (activeTab) {
            case "":
                setLoadingMostRecentData(true);
                const mostRecentResponse = await rget(`/api/sqlserver/filteredMostRecentLicensedCompanies?page=${mostRecentPage}&perPage=${mostRecentPerPage}&companyId=${licensedCompanyFilter.company.id || -1}&companyType=${licensedCompanyFilter.company.type || ''}&companyName=${licensedCompanyFilter.company.name || ''}&companyStateId=${licensedCompanyFilter.state.id || -1}&companyStateName=${licensedCompanyFilter.state.name || ''}&companyCommodityId=${licensedCompanyFilter.commodity.id || -1}&companyCommodityName=${licensedCompanyFilter.commodity.name || ''}&companyLicenseType=${licensedCompanyFilter.companyLicenseType.value || ''}`);
                setMostRecentData(mostRecentResponse.data);
                const mostRecentCountResponse = await rget(`/api/sqlserver/licensedCompaniesCount?companyId=${licensedCompanyFilter.company.id || -1}&companyType=${licensedCompanyFilter.company.type || ''}&companyName=${licensedCompanyFilter.company.name || ''}&companyStateId=${licensedCompanyFilter.state.id || -1}&companyStateName=${licensedCompanyFilter.state.name || ''}&companyCommodityId=${licensedCompanyFilter.commodity.id || -1}&companyCommodityName=${licensedCompanyFilter.commodity.name || ''}&companyLicenseType=${licensedCompanyFilter.companyLicenseType.value || ''}`);
                setMostRecentDataCount(mostRecentCountResponse.data);
                setLoadingMostRecentData(false);
                setMostRecentPage(isTriggeredByFilter ? 1 : mostRecentPage);              
                break;
            // case "All":
            //     setLoadingHistoricalData(true);
            //     const historicalResponse = await rget(`/api/cosmosdb/filteredHistoricalLicensedCompanies?page=${historicalPage}&perPage=${historicalPerPage}&companyId=${licensedCompanyFilter.company.id || -1}&companyType=${licensedCompanyFilter.company.type || ''}&companyName=${licensedCompanyFilter.company.name || ''}&companyStateId=${licensedCompanyFilter.state.id || -1}&companyStateName=${licensedCompanyFilter.state.name || ''}&companyCommodityId=${licensedCompanyFilter.commodity.id || -1}&companyCommodityName=${licensedCompanyFilter.commodity.name || ''}&companyLicenseType=${licensedCompanyFilter.companyLicenseType.value || ''}`);
            //     setHistoricalData(historicalResponse.data);
            //     const historicalCountResponse = await rget(`/api/cosmosdb/historicalLicensedCompaniesCount?companyId=${licensedCompanyFilter.company.id || -1}&companyType=${licensedCompanyFilter.company.type || ''}&companyName=${licensedCompanyFilter.company.name || ''}&companyStateId=${licensedCompanyFilter.state.id || -1}&companyStateName=${licensedCompanyFilter.state.name || ''}&companyCommodityId=${licensedCompanyFilter.commodity.id || -1}&companyCommodityName=${licensedCompanyFilter.commodity.name || ''}&companyLicenseType=${licensedCompanyFilter.companyLicenseType.value || ''}`);
            //     setHistoricalDataCount(historicalCountResponse.data);
            //     setLoadingHistoricalData(false);
            //     setHistoricalPage(isTriggeredByFilter ? 1 : historicalPage);
            //     break;
            default:
                break;
        }
    };

    const handleClearFilteredLicensedCompany = () => {
        setLicensedCompanyFilter({ company: { name: '', type: '', id: '', value: '' }, state: { name: '', id: '', value: '' }, commodity: { name: '', id: '', value: '' }, companyLicenseType: '' });
    };

    const handleMostRecentPageChange = mostRecentPage => {
        setMostRecentPage(mostRecentPage);
	};

    const handleMostRecentPerRowsChange = mostRecentNewPerPage => {
        setMostRecentPerPage(mostRecentNewPerPage);
    };

    const handleHistoricalPageChange = historicalPage => {
        setHistoricalPage(historicalPage);
    };

    const handleHistoricalPerRowsChange = historicalNewPerPage => {
        setHistoricalPerPage(historicalNewPerPage);
    };

    const handleViewLicenseUrl = (urlLicense) => {
        setSelectedLicenseUrlButton(urlLicense);
        window.open(urlLicense, '_blank');
        setSelectedLicenseUrlButton('');
    };

    const handleLicenseTypes = licenseTypes => {

        var finalLicenseTypes = '';

        licenseTypes.split('|').map(function (str) {

            if (str.trim().toLowerCase() != 'n/a') {
                finalLicenseTypes += str + ' - ';
            }
        })

        return finalLicenseTypes.slice(0, -3);
    };

    const tabContents = [
        {
            id: '1',
            title: '',
            icon: 'mdi mdi-home-variant',
            text: ''
        }
        // ,
        // {
        //     id: '2',
        //     title: 'All',
        //     icon: 'mdi mdi-account-circle',
        //     text: 'Coming soon :)'
        // }
    ];

    const columns = [
        {
            name: 'Date Issued',
            selector: row => new Date(row.dateIssued).toLocaleDateString() !== "1/1/1" ? new Date(row.dateIssued).toLocaleDateString() : 'N/A',
            grow: 0.75
        },
        {
            name: 'Company Name',
            selector: row => row.companyName,
            grow: 2
        },
        {
            name: 'State',
            selector: row => row.state,
            grow: 1
        },
        {
            name: 'Commodity',
            selector: row => row.commodity,
            grow: 0.75
        },
        {
            name: 'License Number',
            selector: row => row.licenseNumber,
            grow: 1
        },
        {
            name: 'License Type',
            selector: row => handleLicenseTypes(row.licenseType),
            grow: 1
        },
        {
            name: '',
            selector: row =>
                <OverlayTrigger placement="top" overlay={<Tooltip>View Official Website
                </Tooltip>}>
                    <Button variant="primary" disabled={selectedLicenseUrlButton === row.licenseUrl ? true : false}>
                        <i className="dripicons-preview cursor-pointer" onClick={() => handleViewLicenseUrl(row.licenseUrl)}></i>
                    </Button>
                </OverlayTrigger>,
            grow: 0.2
        }
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Rows per Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    useEffect(() => {
        rget('/api/sqlserver/licensedCompanyNames')
            .then(response => {
                setLicensedCompanyNames(response.data);
            });
        return () => {
            setLicensedCompanyNames();
        };
    }, []);

    useEffect(() => {
        rget('/api/sqlserver/licensedCompanyStates')
            .then(response => {
                setLicensedCompanyStates(response.data);
            });
        return () => {
            setLicensedCompanyStates();
        };
    }, []);

    useEffect(() => {
        rget('/api/sqlserver/licensedCompanyCommodities')
            .then(response => {
                setLicensedCompanyCommodities(response.data);
            });
        return () => {
            setLicensedCompanyCommodities();
        };
    }, []);

    useEffect(() => {
        rget('/api/sqlserver/licensedCompanyLicenseTypes')
            .then(response => {
                setLicensedCompanyLicenseTypes(response.data);
            });
        return () => {
            setLicensedCompanyLicenseTypes();
        };
    }, []);

	useEffect(() => {
        handleFilteredLicensedCompany(false); // fetch page 1 of users
    }, [mostRecentPerPage, mostRecentPage, historicalPerPage, historicalPage, activeTab]);

    const handleSelectFilterValue = (selectedOption, name) => {

        if (name === 'company') {
            let company = licensedCompanyNames.find(d => d.company === selectedOption?.value);

            if (company) {
                setLicensedCompanyFilter({
                    ...licensedCompanyFilter, company: { id: company.id, type: company.type, name: company.company, value: selectedOption }
                })
            }
            else {
                setLicensedCompanyFilter({
                    ...licensedCompanyFilter, company: { id: '', type: '', name: '' }
                })
            }
        } else if (name === 'state') {

            let state = licensedCompanyStates.find(d => d.name === selectedOption?.value);

            if (state) {
                setLicensedCompanyFilter({
                    ...licensedCompanyFilter, state: { id: state.id, name: state.name, value: selectedOption}
                })
            }
            else { 
                setLicensedCompanyFilter({
                    ...licensedCompanyFilter, state: { id: '', name: '' }
                })
            }
        }else if (name === 'commodity') {

            let commodity = licensedCompanyCommodities.find(d => d.name === selectedOption?.value);

            if (commodity) {
                setLicensedCompanyFilter({
                    ...licensedCompanyFilter, commodity: { id: commodity.id, name: commodity.name, value: selectedOption }
                })
            }
            else {
                setLicensedCompanyFilter({
                    ...licensedCompanyFilter, commodity: { id: '', name: '' }
                })
            }
        }
        else {
            setLicensedCompanyFilter({
                ...licensedCompanyFilter, [name]: selectedOption
            })
        }
    };

    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen((prevState) => !prevState);
    };

    const handleActiveTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Body>
                        <h4 className="header-title">
                            {"Licensed companies"}
                        </h4>
                        <p className="text-muted font-14">
                            {"Here is all the information associated with the licenses of the companies where you can also know the latest. In addition, a series of filters (company name, state, commodity and type of license) are available to allow personalized searches."}
                        </p>
                        <hr className="my-2" />
                        {/* <h5 className="mt-2 text-muted">
                            <Link
                                to="#"
                                className={classNames('custom-accordion-title d-block py-1', { collapsed: open !== true })}
                                onClick={toggle}
                                aria-controls={'collapse' + 1}
                                aria-expanded={open}>
                                {!open && <> Show Filters <i className="mdi mdi-chevron-down accordion-arrow"></i></>}
                                {open && <> Hide Filters <i className="mdi mdi-chevron-up accordion-arrow"></i></>}
                            </Link>
                        </h5> */}
                        <Collapse in={open} appear>
                            <div>
                                <Row className="mb-2">
                                    <Col lg={3}>
                                        <Select
                                            searchable
                                            isClearable={true}
                                            placeholder={'Select a company'}
                                            value={licensedCompanyFilter.company.value}
                                            onChange={selectedOption => handleSelectFilterValue(selectedOption, 'company')}
                                            options={licensedCompanyNames &&
                                                licensedCompanyNames.map(company => {
                                                    return (
                                                        { value: company.company, label: company.company }
                                                    );
                                                })
                                            }
                                            className="react-select"
                                            classNamePrefix="react-select">
                                        </Select>
                                    </Col>
                                    <Col lg={3}>
                                        <Select
                                            searchable
                                            isClearable={true}
                                            placeholder={'Select a state'}
                                            value={licensedCompanyFilter.state.value}
                                            onChange={selectedOption => handleSelectFilterValue(selectedOption, 'state')}
                                            options={licensedCompanyStates &&
                                                licensedCompanyStates.map(state => {
                                                    return (
                                                        { value: state.name, label: state.name  }
                                                    );
                                                })
                                            }
                                            className="react-select"
                                            classNamePrefix="react-select">
                                        </Select>
                                    </Col>
                                    <Col lg={3}>
                                        <Select
                                            searchable
                                            isClearable={true}
                                            placeholder={'Select a commodity'}
                                            value={licensedCompanyFilter.commodity.value}
                                            onChange={selectedOption => handleSelectFilterValue(selectedOption, 'commodity')}
                                            options={licensedCompanyCommodities &&
                                                licensedCompanyCommodities.map(commoditiy => {
                                                    return (
                                                        { value: commoditiy.name, label: commoditiy.name }
                                                    );
                                                })
                                            }
                                            className="react-select"
                                            classNamePrefix="react-select">
                                        </Select>
                                    </Col>
                                    <Col lg={3}>
                                        <Select
                                            searchable
                                            isClearable={true}
                                            placeholder={'Select a license type'}
                                            value={licensedCompanyFilter.companyLicenseType}
                                            onChange={selectedOption => handleSelectFilterValue(selectedOption, 'companyLicenseType')}
                                            options={licensedCompanyLicenseTypes &&
                                                licensedCompanyLicenseTypes.map(licenseType => {
                                                    return (
                                                        { value: licenseType, label: licenseType }
                                                    );
                                                })
                                            }
                                            className="react-select"
                                            classNamePrefix="react-select">
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className="text-end">
                                    <Col lg={12}>
                                        <Button variant="primary"
                                            type="submit"
                                            disabled={licensedCompanyFilter.company.name || licensedCompanyFilter.state.name || licensedCompanyFilter.commodity.name || licensedCompanyFilter.companyLicenseType ? false : true}
                                            onClick={() => handleFilteredLicensedCompany(true)}>
                                            Apply Filters
                                        </Button>
                                        <Button className="ms-2"
                                            variant="primary"
                                            type="submit"
                                            disabled={licensedCompanyFilter.company.name || licensedCompanyFilter.state.name || licensedCompanyFilter.commodity.name || licensedCompanyFilter.companyLicenseType ? false : true}
                                            onClick={() => handleClearFilteredLicensedCompany()}>
                                            Clear All Filters
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse>                     
                        {/* <hr className="my-2" /> */}
                        <DataTable
                            columns={columns}
                            data={mostRecentData}
                            progressPending={loadingMostRecentData}
                            progressComponent={<Spinner />}
                            pagination
                            paginationServer
                            paginationTotalRows={mostRecentDataCount}
                            onChangeRowsPerPage={handleMostRecentPerRowsChange}
                            onChangePage={handleMostRecentPageChange}
                            paginationComponentOptions={paginationComponentOptions}
                            paginationPerPage={50}
                            paginationRowsPerPageOptions={[50, 100, 200, 500]}
                        />
                        {/* <Tab.Container activeKey={activeTab} onSelect={handleActiveTab}> */}
                            {/* <Nav variant="tabs" className="nav-bordered" as="ul">
                                {tabContents.map((tab, index) => {
                                    return (
                                        <Nav.Item key={index} as="li">
                                            <Nav.Link href="#" eventKey={tab.title}>
                                                <i
                                                    className={classnames(
                                                        tab.icon,
                                                        'd-md-none',
                                                        'd-block',
                                                        'me-1'
                                                    )}></i>
                                                <span className="d-none d-md-block">{tab.title}</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    );
                                })}
                            </Nav> */}
                            {/* <Tab.Content>
                                {tabContents.map((tab, index) => {
                                    return (
                                        <Tab.Pane className='mt-3' eventKey={tab.title} id={tab.id} key={index}>
                                            <Row>
                                                <Col sm="12">
                                                    {mostRecentData && tab.id == 1 &&
                                                        <DataTable
                                                            columns={columns}
                                                            data={mostRecentData}
                                                            progressPending={loadingMostRecentData}
                                                            progressComponent={<Spinner />}
                                                            pagination
                                                            paginationServer
                                                            paginationTotalRows={mostRecentDataCount}
                                                            onChangeRowsPerPage={handleMostRecentPerRowsChange}
                                                            onChangePage={handleMostRecentPageChange}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            paginationPerPage={50}
                                                            paginationRowsPerPageOptions={[50, 100, 200, 500]}
                                                        />
                                                    } */}
                                                    {/* {historicalData && tab.id == 2 &&
                                                        <DataTable
                                                            columns={columns}
                                                            data={historicalData}
                                                            progressPending={loadingHistoricalData}
                                                            progressComponent={<Spinner />}
                                                            pagination
                                                            paginationServer
                                                            paginationTotalRows={historicalDataCount}
                                                            onChangeRowsPerPage={handleHistoricalPerRowsChange}
                                                            onChangePage={handleHistoricalPageChange}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            paginationPerPage={50}
                                                            paginationRowsPerPageOptions={[50, 100, 200, 500]}
                                                        />
                                                    } */}
                                                {/* </Col>
                                            </Row>
                                        </Tab.Pane>
                                    );
                                })}
                            </Tab.Content>
                        </Tab.Container> */}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
	);
};

export default LicensedCompaniesDataTable;