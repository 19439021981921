import { ICompanyData } from "./types";
import { Image } from 'react-bootstrap';
import Table, { LinkColumn } from "../../components/Table/index";
import { ColumnDef } from "@tanstack/table-core";
import { titleConvert } from './../../helpers/api/utils';
import Text from 'components/Text';
type CompaniesDataProps = {
    companies?: ICompanyData[];
    hiddenColumns?: string[];
    enableActions?: boolean;
    loading?: boolean;
    total?: number;
    hook?: any;
    pageNo?: number;
    filters?: any;
    extra?: any;
};
const imageerror = (e) => {
    e.target.style.display = "none";
}
const columns: ColumnDef<ICompanyData>[] = [
    {
        header: "Company Name",
        accessorKey: "legalName",
        enableSorting: true,
        cell: (props) => {
            if (props.row.original.emailDomain === null || props.row.original.emailDomain === '') {
                return <div className="d-flex">
                    <div className="image-container-logo">
                    </div>
                    <LinkColumn className="link-color" value={titleConvert(props.row.original.legalName.trim())} url={`/companies/${props.row.original.id}`} />
                </div>;
            }
            return <div className="d-flex">
                <div className="image-container-logo">
                    <Image alt="logo images" onError={imageerror} src={'https://logo.clearbit.com/' + props.row.original.emailDomain} />
                </div>
                <LinkColumn className="link-color" value={titleConvert(props.row.original.legalName.trim())} url={`/companies/${props.row.original.id}`} />
            </div>
        },
    },
    {
        header: "Domain",
        accessorKey: "emailDomain",
        enableSorting: true,
        cell: (props) => {
            return <Text variant="link" className="link-color" url={((props.row.original.emailDomain === null || props.row.original.emailDomain === undefined) ? '' : 'http://' + props.row.original.emailDomain)}>
                {((props.row.original.emailDomain === null || props.row.original.emailDomain === undefined) ? '' : props.row.original.emailDomain) }
            </Text>
        },
    },
    {
        header: "Number of Contacts",
        accessorKey: "contactCount",
        enableSorting: true,
        cell: (props) => {
            return <span>{`${(props.row.original.contactCount === null || props.row.original.contactCount === undefined) ? '' : props.row.original.contactCount}`}</span>
        },
    },
    {
        header: "Number of Licenses",
        accessorKey: "licenseCount",
        enableSorting: true,
        cell: (props) => {
            return <span>{`${(props.row.original.licenseCount === null || props.row.original.licenseCount === undefined) ? '' : props.row.original.licenseCount}`}</span>
        },
    },
    {
        header: "License Type",
        accessorKey: "licenseType",
        enableSorting: false,
        cell: (props) => {
            return <span>{`${((props.row.original.licenseType === undefined || props.row.original.licenseType === null || props.row.original.licenseType === "") ? "N/A" : props.row.original.licenseType)}`}</span>
        },
    },

];

export const CompaniesDataTable = ({
    companies,
    loading,
    total,
    hook,
    filters,
    hiddenColumns,
    enableActions = false,
    extra,
}: CompaniesDataProps) => {
    return (
        <>
            <Table<ICompanyData> extra={extra} filters={filters} searchPlaceholder="search name, license or domain" columns={columns} data={companies} loading={loading} total={total} hook={hook} />
        </>
    );
};
