import { ReactNode } from 'react';
import DetailsHeaderTitle from './DetailsHeaderTitle';
import DetailsHeaderFields from './DetailsHeaderFields';
import { Link,useHistory } from 'react-router-dom';
import Div from 'components/Div';
import Text from 'components/Text';
import ArrowLeftIcon from '../icons/ArrowLeft';
type DetailsHeaderProps = {
    icon?: ReactNode;
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
    detailFields?: string | ReactNode;
    rightBox?: ReactNode;
    pageTitle?: string;
};


const DetailsHeader = ({
    icon,
    title,
    pageTitle,
    subtitle,
    detailFields,
    rightBox
}: DetailsHeaderProps) => {
    const history = useHistory();
    return (
        <>
            <Div className="d-flex my-3">
                {icon && (
                    <Div className="me-2 d-flex align-items-center">{icon}</Div>
                )}
                <Div className="d-flex flex-column flex-grow-1">
                    {typeof title === 'string' ? (
                        <Text variant="h1" className="my-0 py-0">
                            {title}
                        </Text>
                    ) : (
                        title
                    )}
                    {typeof subtitle === 'string' ? (
                        <Text variant="link" url={('http://' + subtitle)} className="my-0 py-0 h4" style={{ "color": "#7083d7" }}>
                            {subtitle}
                        </Text>
                    ) : (
                        subtitle
                    )}
                    {typeof detailFields === 'string' ? (
                        <Text variant="subtitle" className="my-0 py-0">
                            {detailFields}
                        </Text>
                    ) : (
                        detailFields
                    )}
                </Div>
                {rightBox && (
                    <Div style={{ width: '300px', overflowY: 'auto' }}>
                        {rightBox}
                    </Div>
                )}
            </Div>
            <Div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link className="link-color" to={'/#'} onClick={() => history.goBack()}><ArrowLeftIcon fontSize={14} /> Companies</Link></li>
                    </ol>
                </nav>
            </Div>
        </>
        
    );
};

export default Object.assign(DetailsHeader, {
    Title: DetailsHeaderTitle,
    DetailFields: DetailsHeaderFields
});
