import { Link } from 'react-router-dom';

type TextLineProps = {
    primary?: string;
    secondary?: string | number;
    url?: string;
    labelClass?: string;
};

const TextLine = ({ primary, secondary, url,labelClass }: TextLineProps) => {
    return (
        <span>
            {primary && <b className={labelClass}>{primary}</b>}
            {primary && secondary && ' '}
            {secondary && !url && <span>{secondary}</span>}
            {secondary && url && (
                <Link to={url} target="_blank" className="fs-11">
                    {secondary}
                </Link>
            )}
        </span>
    );
};

export default TextLine;
