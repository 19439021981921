const pushIntoStateArray = (setStateArray, elementToPush) => {
    setStateArray(existingItems => [...existingItems, elementToPush]);
}

const removeFromStateArray = (setStateArray, elementToRemove) => {
    setStateArray(existingItems => (existingItems.filter(item => item !== elementToRemove)));
}

const checkIfExistsInStateArray = (stateArray, elementToCheckIfExists) => {
    return stateArray.findIndex((element) => element == elementToCheckIfExists) != -1;
}

export { pushIntoStateArray, removeFromStateArray, checkIfExistsInStateArray };
