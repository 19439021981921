import { useState } from 'react';

type UseOrderByValues<T> = {
    orderBy?: keyof T;
    ascendingOrder?: boolean;
};

export function useOrderBy<T>(defaultData: UseOrderByValues<T> = {}) {
    const [orderBy, setOrderBy] = useState<keyof T>(defaultData.orderBy);
    const [ascendingOrder, setAscendingOrder] = useState<boolean>(
        defaultData.ascendingOrder || false
    );

    return { orderBy, setOrderBy, ascendingOrder, setAscendingOrder };
}
