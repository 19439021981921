/**
 * Toggle the class on body
 * @param {*} cssClass
 */
export function manageBodyClass(cssClass: string, action = 'toggle') {
    switch (action) {
        case 'add':
            if (document.body) {
                document.body.classList.add(cssClass);
            }
            break;
        case 'remove':
            if (document.body) {
                document.body.classList.remove(cssClass);
            }
            break;
        default:
            if (document.body) {
                document.body.classList.toggle(cssClass);
            }
            break;
    }

    return true;
}
