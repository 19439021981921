import { IStateWiseLicenseDetails } from './../../companies/types';
import Table from '../../../components/Table/index';
import { ColumnDef } from "@tanstack/table-core";
import Text from '../../../components/Text';
import React from 'react';


type StateSummaryDataProps = {
    statesummary?: IStateWiseLicenseDetails[];
    hiddenColumns?: string[];
    enableActions?: boolean;
    loading?: boolean;
    total?: number;
    hook?: any
}; 
const summaryColumns: ColumnDef<IStateWiseLicenseDetails>[] = [
    {
        header: "State",
        accessorKey: "state",
        enableSorting: false,
        cell: (props) => <Text variant="link" url={`/companies?StateCode=${props.row.original.state}`}>{`${props.row.original.state ?? ""}`}</Text>
        ,
    },
    {
        header: "ABC",
        accessorKey: "abc",
        enableSorting: false,
        cell: (props) => <Text variant="link" url={`/companies?StateCode=${props.row.original.state}&LicenseType=ABC`}>{`${props.row.original.abc ?? ""}`}</Text>,
    },
    {
        header: "Supplier",
        accessorKey: "supplier",
        enableSorting: false,
        cell: (props) => <Text variant="link" url={`/companies?StateCode=${props.row.original.state}&LicenseType=Supplier`}>{`${props.row.original.supplier ?? ""}`}</Text>,
    },
    {
        header: "Total Licenses",
        accessorKey: "totalLicenses",
        enableSorting: false,
        cell: (props) => <span>{`${props.row.original.totalLicenses ?? ""}`}</span>,
    }
];
export const StateSummaryDataTable = ({
    statesummary,
    loading,
}: StateSummaryDataProps) => {
    return (
        <>
            <Table<IStateWiseLicenseDetails> columns={summaryColumns} data={statesummary} loading={loading} enableSearch={false} enablePagination={false} enableHideColumns={false} />
        </>
    );
}