import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from 'react-bootstrap/DropdownItem';
import classNames from 'classnames';
import { Column } from '@tanstack/react-table';

type ColumnTogglerProps<TableValues> = {
    columns: Column<TableValues, any>[];
};

const ColumnToggler = <TableValues extends object = Record<string, never>>({
    columns
}: ColumnTogglerProps<TableValues>) => {
    const toggle = (column: Column<TableValues, any>) => {
        column.toggleVisibility();
    };

    return (
        <Dropdown>
            <Dropdown.Toggle className="btn btn-primary" id="dropdown-basic">
                <i className="mdi mdi-table-headers-eye"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu
                align="end"
                style={{ maxHeight: '500px', overflowY: 'auto' }}
            >
                {columns
                    .filter((column) => column.getCanHide())
                    .map((column) => (
                        <DropdownItem
                            key={column.id}
                            onClick={(event) => {
                                event.stopPropagation();
                                toggle(column);
                            }}
                        >
                            <i
                                className={classNames(
                                    'mdi',
                                    column.getIsVisible()
                                        ? 'mdi-eye'
                                        : 'mdi-eye-off-outline'
                                )}
                            ></i>{' '}
                            {column.columnDef.header.toString()}
                        </DropdownItem>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ColumnToggler;
