import { ReactNode } from 'react';

import Text from 'components/Text';

type DetailsHeaderTitleProps = {
    icon?: ReactNode;
    title: string;
};

const DetailsHeaderTitle = ({ icon, title }: DetailsHeaderTitleProps) => {
    return (
        <Text variant="h1" className="my-0 py-0">
            {icon} {title}
        </Text>
    );
};

export default DetailsHeaderTitle;
