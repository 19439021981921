import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';

export const arrowLeftIcon: IconDefinition = {
    prefix: 'fas', // Leave as it is to avoid conflict with font awesome component
    iconName: '0', // Leave as it is to avoid conflict with font awesome component
    icon: [
        512, // width
        512, // height,
        [''], // Leave as it is to avoid conflict with font awesome component
        '', // Leave as it is to avoid conflict with font awesome component
        'M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z'
    ]
};

const ArrowLeftIcon = (props: Omit<FontAwesomeIconProps, 'icon'>) => {
    return <FontAwesomeIcon icon={arrowLeftIcon} {...props} />;
};

export default ArrowLeftIcon;