import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import SearchIcon from 'components/icons/Search';
import { useHistory, useLocation } from "react-router-dom";

type SearchTableProps = {
    search: string;
    setSearch: Dispatch<SetStateAction<string>>;
    setPage: Dispatch<SetStateAction<number>>;
    disabled?: boolean;
    inputDisabled?: boolean;
    buttonDisabled?: boolean;
    placeholder?: string;
};

const SearchTable = ({
    search,
    setSearch,
    setPage,
    disabled,
    placeholder,
    inputDisabled,
    buttonDisabled
}: SearchTableProps) => {
    const history = useHistory();
    const location = useLocation();
    const [value, setValue] = useState<string>(search);
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const onEnterKeyPressed = (event) => {
        if (event.key === 'Enter') {
            reset();
            setSearch(event.target.value);

        }
    };
    const reset = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("PageNo");
        history.push({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
        setPage(0);
    };
    return (
        <InputGroup>
            <FormControl
                value={value}
                onChange={onChange}
                onKeyUp={onEnterKeyPressed}
                placeholder={placeholder??"Search..."}
                width={200}
                disabled={disabled || inputDisabled}
            />
            <Button
                variant="primary"
                id="button-addon2"
                onClick={() => { reset(); setSearch(value) }}
                disabled={disabled || buttonDisabled}
            >
                <SearchIcon />
            </Button>
        </InputGroup>
    );
};

export default SearchTable;
