import _ from 'lodash';

export const groupByFields = (array: Array<any>, f: any): Array<any> => {
    /*
    params description :
        f : function which returnf the array of fields 
        e.g. :  (item) => {
            return [itemField1, itemField2];
        }
        array : array of data to group e.g. : [{...}, {...}]       
    */
    const groups: { [key: string]: any[] } = {};
    array.forEach((o) => {
        const group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });

    return Object.keys(groups).map((group) => {
        return groups[group];
    });
};

/**
 * split array into chunks
 * @param array - array to split
 * @param chunkSize - chunk size
 * @returns
 */
export const splitArray = <T>(array: Array<T>, chunkSize: number) => {
    const chunks = Array(Math.ceil(array.length / chunkSize))
        .fill(1)
        .map((_, index) => index * chunkSize)
        .map((begin) => array.slice(begin, begin + chunkSize));
    return chunks;
};

export const removeDuplicates = (array: any[]): any[] => {
    return _.uniqWith(array, _.isEqual);
};

/* export const removeDuplicates = (array: any[]) => {
    const size = _.cloneDeep(array.length);
    for (let i = 0; i < size; i++) {
        if (_.isEqual(array[i], array[i + 1])) {
            array.splice(i, 1);
            //i = 0;
            console.log('array', array, i);
        }
    }
    return array;
}; */

/* export const removeDuplicates = (array: any[]): any[] => {
    console.log('removeDuplicates', array);

    return array.filter((item, index) => {
        return array.indexOf(item) === index;
    });
}; */

/* export const removeDuplicates = (array: Array<any>, key: string) => {
    return array.filter(
        (item, index, self) =>
            index === self.findIndex((t) => t[key] === item[key])
    );
}; */

/* export const removeDuplicates = (array: any[]): any[] =>{ return [...new Set(array)]}  */
