import React, { useState, useEffect } from 'react';
import { Card, Table, OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import { rget } from '../../utils/requests';
import Spinner from '../../components/Spinner';
import ButtonSpinner from '../../components/ButtonSpinner';
import * as stateHelper from '../../utils/state';

const OffersRawData = () => {

    const [downloadButtonIsLoading, setDownloadButtonIsLoading] = useState([]);
    const [offers, setOffers] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        rget('/api/RawData/OffersRawData')
            .then(response => {
                setOffers(response.data);
                setIsLoading(false);
            });
    }, []);

    const handleDownloadButtonClick = (actorId) => {
        stateHelper.pushIntoStateArray(setDownloadButtonIsLoading, actorId);

        rget('/api/RawData/downloadOffersRawData/' + actorId)
            .then(response => {
                window.location = response.data;
            })
            .catch(() => {
            })
            .then(() => {
                stateHelper.removeFromStateArray(setDownloadButtonIsLoading, actorId);
            });
    };

    return (
        <Card>
            <Card.Body>
                <h4 className="header-title">
                    {"Offers Raw Data"}
                </h4>
                <p className="text-muted font-14 mb-4">
                    {"Here's the offers raw data for you to download and use however you see fit."}
                </p>
                {
                    isLoading
                        ? <div className="d-flex justify-content-center">
                            <Spinner />
                        </div>
                        : <Table className="table table-centered mb-0">
                            <thead>
                                <tr>
                                    <th class="text-left">{"State"}</th>
                                    <th class="text-left">{"Commodity"}</th>
                                    <th class="text-left">{"Supplier"}</th>
                                    <th class="text-left">{"Utility"}</th>
                                    <th class="text-center">{"Has Residential Data?"}</th>
                                    <th class="text-center">{"Has Commercial Data?"}</th>
                                    <th class="text-center">{""}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {offers.map((item, index) =>
                                    <tr key={index}>
                                        <th class="text-left">{item.state}</th>
                                        <td class="text-left">{item.commodity}</td>
                                        <td class="text-v">{item.supplier}</td>
                                        <td class="text-left">{item.utility}</td>
                                        <td class="text-center">{item.residential ? <i class="text-success dripicons-checkmark"></i> : <i class="text-danger dripicons-cross"></i>}</td>
                                        <td class="text-center">{item.smallCommercial ? <i class="text-success dripicons-checkmark"></i> : <i class="text-danger dripicons-cross"></i>}</td>
                                        <td class="text-center">
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Download</Tooltip>}>
                                                <Button variant="primary" disabled={stateHelper.checkIfExistsInStateArray(downloadButtonIsLoading, item.id)}
                                                    onClick={(e) => handleDownloadButtonClick(item.id, e)}>
                                                    {!stateHelper.checkIfExistsInStateArray(downloadButtonIsLoading, item.id) ?
                                                        <i className='dripicons-cloud-download'></i> : <ButtonSpinner />}
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                }
            </Card.Body>
        </Card>
    )
};

export default OffersRawData;
