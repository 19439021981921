import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { SortDirection } from '@tanstack/react-table';

import Div from 'components/Div';

type SortingIconProps = {
    isSorted?: SortDirection | false;
};

const SortingIcon = ({ isSorted = false }: SortingIconProps) => {
    return (
        <Div
            className="d-flex flex-column"
            style={{ width: 'fit-content' }}
            role="button"
        >
            <FontAwesomeIcon
                size="sm"
                icon={faSortUp}
                className={classNames(
                    isSorted === 'asc' ? 'text-primary' : 'text-secondary'
                )}
            />
            <FontAwesomeIcon
                size="sm"
                icon={faSortDown}
                className={classNames(
                    isSorted === 'desc' ? 'text-primary' : 'text-secondary'
                )}
                style={{ marginTop: '-10px' }}
            />
        </Div>
    );
};

export default SortingIcon;
