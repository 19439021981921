import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';

export const customerIcon: IconDefinition = {
    prefix: 'fas', // Leave as it is to avoid conflict with font awesome component
    iconName: '0', // Leave as it is to avoid conflict with font awesome component
    icon: [
        640, // width
        512, // height,
        [''], // Leave as it is to avoid conflict with font awesome component
        '', // Leave as it is to avoid conflict with font awesome component
        'M64 48H320c8.8 0 16 7.2 16 16V415.7c11.3-20 27.9-36.7 48-47.9V271.8 64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c3.2 0 6.4-.2 9.5-.7c-6-10-9.5-21.8-9.5-34.4c0-4.4 .2-8.7 .7-13c-.2 0-.4 0-.7 0H240V400c0-26.5-21.5-48-48-48s-48 21.5-48 48v64H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16zm24 56v48c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V104c0-8.8-7.2-16-16-16H104c-8.8 0-16 7.2-16 16zM232 88c-8.8 0-16 7.2-16 16v48c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V104c0-8.8-7.2-16-16-16H232zM88 232v48c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V232c0-8.8-7.2-16-16-16H104c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v48c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V232c0-8.8-7.2-16-16-16H232zm344 56a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM352 477.1c0 19.3 15.6 34.9 34.9 34.9H605.1c19.3 0 34.9-15.6 34.9-34.9c0-51.4-41.7-93.1-93.1-93.1H445.1c-51.4 0-93.1 41.7-93.1 93.1z'
    ]
};

const CustomerIcon = (props: Omit<FontAwesomeIconProps, 'icon'>) => {
    return <FontAwesomeIcon icon={customerIcon} {...props} />;
};

export default CustomerIcon;
