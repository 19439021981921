import { faFire } from '@fortawesome/free-solid-svg-icons';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const GasIcon = ({
    className,
    ...props
}: Omit<FontAwesomeIconProps, 'icon'>) => {
    return (
        <FontAwesomeIcon
            icon={faFire}
            className={classNames('text-success', className)}
            {...props}
        />
    );
};

export default GasIcon;
