import { ContactInfo } from "./companyDetailsV2/ContactInfo.js";
import { LicenseAndCaseHistories } from "./companyDetailsV2/LicenseAndCaseHistories.js";
import { StateServices } from "./companyDetailsV2/StateServices.js";
import { AliasesData } from './companyDetailsV2/AliasesService.js'

export const companyTabContents = [
  {
    id: "1",
    title: "Licenses",
    text: "",
    content: LicenseAndCaseHistories,
    },
    {
        id: "2",
        title: "Contacts",
        text: "",
        content: ContactInfo,
    },
    {
        id: "3",
        title: "Aliases",
        text: "",
        content: AliasesData,
    },
  // {
  //   id: "3",
  //   title: "State & Services",
  //   text: "",
  //   content: StateServices,
  // },
];

export const mockDataLicense = {
  data: {
    license: [
      {
        state: "TX",
        licenseNuber: 21223,
        ValidFrom: "1.1.2024",
        ValidTo: "1.1.2024",
        status: "Active",
      },
      {
        state: "CA",
        licenseNuber: 21223,
        ValidFrom: "1.1.2024",
        ValidTo: "1.1.2024",
        status: "Active",
      },
    ],
    CaseHistory: [
      {
        state: "TX",
        caseNumber: "123321",
        caseType: "severe",
        caseOpenDate: "1.1.24",
        caseCloseDate: "1.1.24",
        caseTitle: "N/A",
        caseNote: "-",
      },
      {
        state: "MI",
        caseNumber: "123321",
        caseType: "severe",
        caseOpenDate: "1.1.24",
        caseCloseDate: "1.1.24",
        caseTitle: "N/A",
        caseNote: "-",
      },
    ],
  },
};

export const mockDataContactInfo = {
    contact: [
      {
        state: "California",
        name: "John Doe",
        phone: "(123) 456-7890",
        fax: "(123) 456-7891",
        type: "Business",
        role: "Manager",
        email: "john.doe@example.com",
      },
      {
        state: "Texas",
        name: "Jane Smith",
        phone: "(234) 567-8901",
        fax: "(234) 567-8902",
        type: "Personal",
        role: "Developer",
        email: "jane.smith@example.com",
      },
    ],
    Addresses: [
      {
        state: "California",
        companyName: "Tech Innovators Inc.",
        address: "123 Silicon Valley Blvd",
        zipcode: "94025",
      },
      {
        state: "New York",
        companyName: "Finance Experts LLC",
        address: "456 Wall Street",
        zipcode: "10005",
      },
    ],
};

export const mockDataStateServices = {
    "States & Commodities": [
      {
        state: "California",
        commodityName: "Gas",
        utilities: [
          {
            utilityName: "Pacific Gas and Electric Company",
          },
          {
            utilityName: "Texas Power and Light",
          },
        ]
      },
      {
        state: "Texas",
        commodityName: "Electric",
        utilities: [
          {
            utilityName: "Pacific Gas and Electric Company",
          },
          {
            utilityName: "Texas Power and Light",
          },
        ],
      },
    ],
};

export const mockDataObjects = {
  "Licenses & Case Histories": mockDataLicense,
  "Contact Info": mockDataContactInfo,
  "State & Services": mockDataStateServices,
};
export const mockDataAliasesInfo = {
    "data": [
        {
            state: "California",
            companyName: "Tech Innovators Inc.",
            address: "123 Silicon Valley Blvd",
            zipcode: "94025",
        },
        {
            state: "New York",
            companyName: "Finance Experts LLC",
            address: "456 Wall Street",
            zipcode: "10005",
        },
    ]
}
