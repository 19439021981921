const downloadFile = (data, filename, mime, bom) => {
    var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    } else {
        var blobURL =
            window.URL && window.URL.createObjectURL
                ? window.URL.createObjectURL(blob)
                : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200);
    }
};
const convertUrlToDomain = (url) => {
    try {
        // Create a new URL object
        const urlObj = new URL(url);

        // Extract the hostname
        const hostname = urlObj.hostname;

        // Remove 'www.' if present
        const domain = hostname.replace(/^www\./, '');

        return domain;
    } catch (error) {
        //console.error('Invalid URL:', error);
        return '';
    }
}

const sortByProperty = (arr, properties)=> {
    return arr.sort((a, b) => {
        for (let prop of properties) {
            const aValue = a[prop];
            const bValue = b[prop];

            const isAInvalid = aValue === undefined || aValue === null || aValue === '' || aValue === ' ';
            const isBInvalid = bValue === undefined || bValue === null || bValue === '' || bValue === ' ';

            if (isAInvalid && isBInvalid) continue;
            if (isAInvalid) return 1;
            if (isBInvalid) return -1;
        }
        return 0;
    });
}
const filterRecords = (records, properties)=> {
    return records.filter(record => {
        // Check if all specified properties in the record are blank, empty, null, or undefined
        const allBlank = properties.every(prop => {
            const value = record[prop];
            return value === undefined || value === null || value === '' || (typeof value === 'string' && value.trim() === '');
        });

        // Include the record only if not all properties are blank
        return !allBlank;
    });
}
const OnlyAddress = (address, city, state, zipcode) => {
    address = address.toLowerCase().replace(city.toLowerCase(), '').replace(`${state.toLowerCase()} ${zipcode.toLowerCase()}`, '').replace(`${state.toLowerCase()},${zipcode.toLowerCase()}`, '').replace(`${state.toLowerCase()}, ${zipcode.toLowerCase()}`, '').replace(` ${state.toLowerCase()},`, '').replace(`,${state.toLowerCase()},`, '');
    var temp = address.split(',');
    return titleConvert(temp.filter(x => x.trim() !== '').join().trim());
}
const titleConvert = (title) => {
    return title
        .split(' ') // Split the title into words
        .map(word => {
            // Check if the word starts with non-alphabetic characters
            const leadingChars = word.match(/^\W*/)[0]; // Matches leading non-alphabetic characters
            const coreWord = word.slice(leadingChars.length); // Remaining part of the word

            if (coreWord.length === 0) {
                // If there's no alphabetic part in the word
                return leadingChars;
            }

            // Capitalize the first letter and lowercase the rest
            const formattedWord = coreWord.charAt(0).toUpperCase() + coreWord.slice(1).toLowerCase();

            return leadingChars + formattedWord; // Combine leading characters with formatted word
        })
        .join(' '); // Join the words back into a single string
}

const trimInvalidDate = (date) => {
    if (date === undefined || date === null || date === '' || date.toString().includes('0001-01-01')) {
        return ''
    }
    const _date = new Date(date);

    // Check if the input date is valid
    if (isNaN(_date.getTime())) {
        throw new Error('Invalid date string');
    }
    // Extract month, day, and year from the Date object
    const part = date.split('-');
    if (part.length > 2) {
        const month = part[1];
        const day = part[2];
        const year = part[0];
        date = `${month}/${day}/${year}`;
    }
    //const month = String(_date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    //const day = String(_date.getDate()).padStart(2, '0');
    //const year = _date.getFullYear();

    // Format and return the date string in MM/dd/yyyy format
    return date;
}
export { downloadFile, convertUrlToDomain, trimInvalidDate, titleConvert, sortByProperty, filterRecords, OnlyAddress };
