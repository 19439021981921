import React, { useState, useEffect } from 'react';
import { Card, Table, OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import { rget } from '../../utils/requests';
import Spinner from '../../components/Spinner';
import ButtonSpinner from '../../components/ButtonSpinner';
import * as stateHelper from '../../utils/state';

const LicensesRawData = () => {

    const [downloadButtonIsLoading, setDownloadButtonIsLoading] = useState([]);
    const [licenses, setLicenses] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        rget('/api/RawData/licensesRawData')
            .then(response => {
                setLicenses(response.data);
                setIsLoading(false);
            });
    }, []);

    const handleDownloadButtonClick = (actorId) => {
        stateHelper.pushIntoStateArray(setDownloadButtonIsLoading, actorId);

        rget('/api/RawData/downloadLicensesRawData/' + actorId)
            .then(response => {
                window.location = response.data;
            })
            .catch(() => {
            })
            .then(() => {
                stateHelper.removeFromStateArray(setDownloadButtonIsLoading, actorId);
            });
    };

    return (
        <Card>
            <Card.Body>
                <h4 className="header-title">
                    {"Licenses Raw Data"}
                </h4>
                <p className="text-muted font-14 mb-4">
                    {"Here's the licenses raw data for you to download and use however you see fit."}
                </p>
                {
                    isLoading
                        ? <div className="d-flex justify-content-center">
                            <Spinner />
                        </div>
                        : <Table className="table table-centered mb-0">
                            <thead>
                                <tr>
                                    <th className="text-left">{"State"}</th>
                                    <th className="text-left">{"Commodity"}</th>
                                    <th className="text-center">{"Has Brokers?"}</th>
                                    <th className="text-center">{"Has Suppliers?"}</th>
                                    <th className="text-center">{"Has Aggregators?"}</th>
                                    <th className="text-center">{""}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {licenses.map((item, index) =>
                                    <tr key={index}>
                                        <th className="text-left">{item.state}</th>
                                        <td className="text-left">{item.commodity}</td>
                                        <td className="text-center">{item.broker ? <i class="text-success dripicons-checkmark"></i> : <i class="text-danger dripicons-cross"></i>}</td>
                                        <td className="text-center">{item.supplier ? <i class="text-success dripicons-checkmark"></i> : <i class="text-danger dripicons-cross"></i>}</td>
                                        <td className="text-center">{item.aggregator ? <i class="text-success dripicons-checkmark"></i> : <i class="text-danger dripicons-cross"></i>}</td>
                                        <td className="text-center">
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Download</Tooltip>}>
                                                <Button variant="primary" disabled={stateHelper.checkIfExistsInStateArray(downloadButtonIsLoading, item.id)}
                                                    onClick={(e) => handleDownloadButtonClick(item.id, e)}>
                                                    {!stateHelper.checkIfExistsInStateArray(downloadButtonIsLoading, item.id) ?
                                                        <i className='dripicons-cloud-download'></i> : <ButtonSpinner/>}
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                }
            </Card.Body>
        </Card>
    )
};

export default LicensesRawData;
