import React from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";


const Profile = () => {

    const { user, logout } = useAuth0();

    const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

    return (
        <div>
             <img
            src={user.picture}
            alt="Profile"
          />       
          <h2>{user.name}</h2>
          <p>{user.email}</p>

          <button onClick={() => logoutWithRedirect()}>{'Logout'}</button>
        </div>
    );
};

export default withAuthenticationRequired(Profile, {
    onRedirecting: () => "Loading...",
  });
  