import axios from "axios";
import configJson from "clientAppSettings.json";
import configJsonDev from "clientAppSettings.Development.json";

var config = configJson;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    config = configJsonDev;
}


export const DEV_URL = config.API_URL;

export const rget = async (endpoint) => {
  try {
    const jwt = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${jwt}` },
    };
    return await axios.get(endpoint, config);
  } catch (error) {
    return "Data not found"
  }
};

export const rpost = async (endpoint, data) => {
  try {
    const jwt = localStorage.getItem("token");
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };
    return await axios.post(endpoint, data, headers);
  } catch (error) {
    return "Data not found"
  }
};
