// set value in the localStorage
export const setValue = (key: string, value: string): boolean => {
    if (!localStorage) {
        return false;
    }

    try {
        localStorage.setItem(key, value);
        return true;
    } catch (e) {
        return false;
    }
};

// get value from localStorage whith validation
export const getValue = (key: string): string => {
    if (!window.localStorage) {
        return null;
    }

    try {
        const authToken = localStorage.getItem(key);
        return authToken;
    } catch (e) {
        return null;
    }
};

// delete item from localStorage
export const deleteItem = (key: string): boolean => {
    if (!localStorage) {
        return false;
    }

    try {
        localStorage.removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
};
